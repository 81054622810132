/**
 * 店铺相关API
 */
import md5 from 'js-md5'
import request from '@/utils/request'

/**
 * 添加供应商
 * @param params
 * @returns {*}
 */
//添加供应商

//获取供应商列表
export function getShopList(params) {
	return request({
		url: 'admin/shops',
		method: 'get',
		loading: false,
		params
	})
}

// export function addShopt(params) {
// 	return request({
// 		url: 'admin/shops',
// 		method: 'post',
// 		params
// 	})
// }
//后台添加供应商
export function addShop(params) {
	params.password = md5(params.password)
	return request({
		url: 'admin/shops/saveSupplier',
		method: 'post',
		data: params
	})
}
export function editShop(params,shop_id){
   let temp = JSON.parse(JSON.stringify(params));
   if(temp.password){
      temp.password = md5(temp.password);
   }
  console.log(temp.password,"加密后的密码")
  return request({
    url: `admin/shops/editSupplier/${shop_id}`,
    method: 'put',
    data:temp
  })

}
//后台添加企业
export function addEnterprise(params) {
	// params.password = md5(params.password)
	return request({
		url: '/admin/shops/saveEnterprise',
		method: 'post',
		data: params
	})
}
//获取变更记录表
export function retentionMoneyLog(params) {
	return request({
		url: '/admin/shops/retentionMoneyLog',
		method: 'get',
		params
	})
}
//添加变更记录
export function addMoneyLog(params) {
	return request({
		url: '/admin/shops/retentionMoneyLog',
		method: 'post',
		data: params
	})
}
export function getShop(id,params) {
	return request({
		url: `/admin/shops/supplier/${id}`,
		method: 'get',
		data: params
	})
}

//账户启用状态
export function switchShop(shop_id,params) {
	return request({
		url: `/admin/shops/editShopUse/${shop_id}`,
		method: 'put',
		data: params
	})
}

/**
 * 获取供应商列表
 * @param params
 * @returns {Promise<any>}
 */
//
//禁用店铺
export function closeShop(shop_id,params) {
	return request({
		url: `/admin/shops/Supplier/${shop_id}`,
		method: 'delete',
		data: params
	})
}

//添加站内信消息
export function addmess(params) {
	return request({
		url: '/admin/shops/shop-notice-logs',
		method: 'post',
		data: params
	})
}
//搜索企业等级表列表
export function getAllList(params) {
	return request({
		url: '/admin/shops/shopLv/getAllList',
		method: 'get',
		data: params
	})
}

/** 短信提醒发货 */
export const smsRemind2Delivery = (params) => request({
  url: '/admin/shops/smsRemind2Delivery',
  method: 'post',
  loading: false,
  params
})

/** 一小时只能发一次 判断是否可以发送 */
export const canSmsRemind2Delivery = _ => request({
  url: '/admin/shops/canSmsRemind2Delivery',
  method: 'post',
  loading: false,
})

