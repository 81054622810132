<template>
  <div class="supplier-list-page">
    <en-table-layout :tableData="tableData.data" :loading="loading" @search="search"
      @selection-change="handleSelectionChange" @sort-change="handleSortChange">
      <template slot="toolbar">
        <div class="toolbar-box">
          <el-form-item label="注册时间">
            <el-date-picker size="small" style="width: 125px;" v-model="time" type="date" placeholder="选择日期"
              @change="timeChange" clearable />
          </el-form-item>

          <el-form-item label="类型">
            <el-select size="small" v-model="params.account_period" style="width: 90px">
              <el-option label="全部" value=""></el-option>
              <el-option label="有账期" value="1"></el-option>
              <el-option label="无账期" value="0"></el-option>
            </el-select>
          </el-form-item>

          <div>
            <el-form-item label="关键词" class="keywords-input">
              <el-input size="small" v-model="keywords.value" placeholder="请输入关键词">
                <el-select v-model="keywords.currentKey" slot="prepend" style="width: 120px">
                  <template v-for="(item) of keywords.keys">
                    <el-option :label="item[0]" :value="item[1]" :key="item[1]"></el-option>
                  </template>
                </el-select>
              </el-input>
            </el-form-item>

            <el-button @click="search" size="small" type="primary">
              搜索
            </el-button>
          </div>
        </div>
      </template>

      <template slot="toolbar_btn">
        <el-button type="primary" @click="supplier.show()" size="small">
          添加供应商
        </el-button>

        <el-button type="primary" size="small" @click="runBatchOps(1)">
          批量启用
        </el-button>

        <el-button type="primary" size="small" @click="runBatchOps(2)">
          批量停用
        </el-button>

        <el-button type="primary" size="small" @click="runBatchOps(3)">
          批量删除
        </el-button>

        <el-button type="primary" @click="senMessage" size="small">
          发送站内信
        </el-button>

        <el-button type="primary" @click="exportAll" size="small">
          导出全部
        </el-button>

        <el-button type="primary" @click="smsTip" size="small">
          短信提醒发货
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" width="50" fixed="left"></el-table-column>

        <el-table-column label="供应商ID" prop="shop_id" width="100" fixed="left"></el-table-column>

        <el-table-column label="供应商名称" prop="shop_name" min-width="180" fixed="left"
          show-overflow-tooltip></el-table-column>

        <el-table-column label="供应商账户" prop="uname" width="180"></el-table-column>


        <el-table-column label="联系人" width="100" prop="link_name" show-overflow-tooltip></el-table-column>

        <el-table-column label="联系方式" width="180" prop="link_phone" show-overflow-tooltip></el-table-column>

        <el-table-column label="负责人" width="100" prop="principal_name" show-overflow-tooltip></el-table-column>

        <el-table-column label="类型" width="100" prop="account_period">
          <template v-slot="{ row }">
            {{ row.account_period === 1 ? '有账期' : '无账期' }}
          </template>
        </el-table-column>

        <el-table-column label="待发货订单" width="120" prop="order_sum_sort" sortable="custom">
          <template v-slot="{ row }">
            {{ row.order_sum }}
          </template>
        </el-table-column>

        <el-table-column label="启用状态" width="80" fixed="right">
          <template slot-scope="scope">
            <el-switch @change="switchChange(scope.row)" v-model="scope.row.memberDisbled" active-color="#2155d5"
              inactive-color="#ff4949"></el-switch>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="350" style="text-align: left">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editSupplier(scope.row.shop_id);">
              编辑
            </el-button>
            <el-button type="primary" size="mini" @click="changezhibao(scope.row)">扣除质保金
            </el-button>
            <el-button type="primary" size="mini" @click="changehis(scope.row)">变更记录
            </el-button>
            <el-button v-if="scope.row.shop_disable === 'OPEN'" type="danger" size="mini"
              @click="desupplier(scope.row)">删除
            </el-button>
            <!--            <el-button-->
            <!--              v-else-->
            <!--              type="primary"-->
            <!--              size="mini"-->
            <!--              @click="changehiss(scope.row) 该方法不存在"-->
            <!--            >启用-->
            <!--            </el-button>-->
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :page-size="tableData.page_size"
        :layout="MixinTableLayout" :total="tableData.data_total" background></el-pagination>

    </en-table-layout>

    <!--发送站内信 dialog-->
    <el-dialog-x-dialog title="发送站内信" :visible.sync="senMessageBool">
      <el-form :model="mesForm" :rules="rules" ref="mesForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标题" prop="title">
          <el-input v-model="mesForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="notice_content">
          <el-input type="textarea" v-model="mesForm.notice_content"></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button @click="cancel" size="small">取消
          </el-button>
          <el-button type="primary" @click="send('mesForm')" size="small">确定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
    <el-dialog-x-dialog title="提示" :visible.sync="isSmsTip" class="sms-tip">
      <div style="margin: 20px 0 10px 0;">
        <p>确定要给当前筛选条件下的供应商发送订单发货提醒的短信吗？</p>
        <div style="text-align:right;">
          <el-button @click="isSmsTip = false" size="small">取消
          </el-button>
          <el-button type="primary" @click="confirmSendSms" size="small">确定
          </el-button>
        </div>
      </div>
    </el-dialog-x-dialog>
    <!-- 添加供应商 -->
    <el-dialog-x-dialog :title="supplier.title" :visible.sync="supplier.isShowDialog" class="supplier-edit-dialog"
      width="650px" :before-close="supplier.beforeDialogClose" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <!-- style="margin-top: 20px;max-height: 75vh; overflow-y: auto;" -->
      <div style="margin-top: 20px;">
        <el-form :model="supplier.data" :rules="supplier.rules" ref="supplierForm" label-width="120px" size="small">
          <div style="max-height: 70vh;overflow-y: auto;">
            <el-form-item label="供应商类型" prop="type">
              <el-select style="width: 150px" v-model="supplier.data.type" placeholder="请选择供应商类型"
                :disabled="supplier.isEditMode">
                <el-option label="无账期" :value="0" />
                <el-option label="有账期" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="供应商名称" prop="name">
              <el-input style="width: 300px" :maxlength="20" v-model="supplier.data.name"
                :disabled="supplier.data.id == 66"></el-input>
            </el-form-item>

            <el-form-item label="供应商账号" prop="account">
              <el-input style="width: 300px" :maxlength="20" v-model="supplier.data.account"></el-input>
            </el-form-item>

            <el-form-item label="供应商密码" prop="password">
              <el-input style="width: 300px" :maxlength="20" v-model="supplier.data.password"></el-input>
            </el-form-item>

            <el-form-item label="绑定手机号" prop="mobile">
              <el-input style="width: 300px" :maxlength="11" v-model="supplier.data.mobile"></el-input>
            </el-form-item>

            <div class="group-title">
              供应商其他信息
            </div>

            <el-form-item label="平台负责人名称" prop="cadre_name">
              <el-input style="width: 300px" :maxlength="32" v-model="supplier.data.cadre_name"></el-input>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="contact_name">
              <el-input style="width: 300px" :maxlength="32" v-model="supplier.data.contact_name"></el-input>
            </el-form-item>

            <el-form-item label="联系人手机号" prop="contact_mobile">
              <el-input style="width: 300px" :maxlength="11" v-model="supplier.data.contact_mobile"></el-input>
              <span style="margin: 0 10px;"></span>
              <el-checkbox v-model="supplier.data.checked"
                :disabled="isClickSms ? true : !supplier.data.contact_mobile">接收订单短信提醒</el-checkbox>
              <br>
              <!-- <span style="color: #999999;">提示：供应商联系方式仅用于平台线下联系供应商使用。</span> -->
            </el-form-item>

            <el-form-item label="联系人手机号2" prop="contact_mobile2">
              <el-input style="width: 300px" :maxlength="11" v-model="supplier.data.contact_mobile2"></el-input>
              <span style="margin: 0 10px;"></span>
              <el-checkbox v-model="supplier.data.checked2"
                :disabled="isClickSms ? true : !supplier.data.contact_mobile2">接收订单短信提醒</el-checkbox>
              <br>
              <!-- <span style="color: #999999;">提示：供应商联系方式仅用于平台线下联系供应商使用。</span> -->
            </el-form-item>
            <el-form-item>
              <div style="color: #999999;line-height: 2;">
                <div>提示：</div>

                <div>1、供应商联系人仅用于平台线下联系供应商使用；</div>

                <div style="padding-right: 46px;">2、接收订单短信提醒勾选后，大平台可在精选优品子订单列表处，给该手机号发送订单发货提醒；</div>
              </div>
              <br>
              <!-- <span style="color: #999999;">提示：供应商联系方式仅用于平台线下联系供应商使用。</span> -->
            </el-form-item>
          </div>
          <el-form-item style="text-align: right;padding-right: 10px;">
            <div style="padding-top: 10px;">
              <el-button size="small" @click="supplier.hide()">
                取 消
              </el-button>
              <el-button type="primary" @click="supplier.save()" size="small">
                确 定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog-x-dialog>

    <!-- 扣除质保金 -->
    <el-dialog-x-dialog title="扣除质保金" :visible.sync="cdialogGood" width="500px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form :model="cgooddata" :rules="cgooddataRules" ref="cgooddata" label-width="120px">
        <!--是否通过=-->
        <el-form-item label="供应商名称" prop="shop_name">
          <!-- <el-input
						type="text"
						:maxlength="20"
						v-model="cgooddata.shop_name">
          </el-input>-->
          {{ cgooddata.shop_name }}
        </el-form-item>
        <el-form-item label="扣除金额" prop="price ">
          <el-input type="text" :maxlength="20" placeholder="变化金额大于零为扣除，小于零为添加" v-model="cgooddata.price"></el-input>
        </el-form-item>
        <el-form-item label="扣除理由" prop="reason">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入审核备注(120字以内)" :maxlength="120"
            v-model="cgooddata.reason"></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button @click="cdialogGood = false" size="small">取 消
          </el-button>
          <el-button type="primary" @click="handleStockGoods('cgooddata')" size="small">确 定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_supplier from "@/api/supplier";
import * as API_shop from "@/api/shop";
import { RegExp } from "@/../ui-utils";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import { validatePhone, validateEmail, validateUname } from "@/utils/validate";
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

class Supplier {
  constructor(ctx, key) {
    this.isShowDialog = false;
    this.title = '';
    this.rules = {};

    this.getPage = () => ctx;
    this.getForm = () => ctx.$refs[key];
    this.beforeDialogClose = () => this.getForm().resetFields();
    this.show = (data) => {
      this.reset(data);
      this.isEditMode = !!data;
      this.title = this.isEditMode ? '编辑供应商' : '添加供应商';
      this.isShowDialog = true;
    };
    this.hide = () => {
      this.isShowDialog = false;
    };
    this.save = () => this.doSave();

    this.reset();
    this.buildRules();
  }

  buildRules () {
    const mobileValidator = {
      validator: (rule, value, callback) => {
        if (!RegExp.mobile.test(value)) {
          callback(new Error("请输入11位有效数字"));
        } else {
          callback();
        }
      },
      validator2: (rule, value, callback) => {
        if (!value) callback() // 允许为空
        else if (this.data.contact_mobile === value) callback(new Error("两个联系人手机号不可重复"));
        else if (!RegExp.mobile.test(value)) callback(new Error("请输入11位有效数字"));
        else callback();
      },
      trigger: "blur",
    };

    this.rules = {
      name: [
        {
          required: true,
          message: "必填",
          trigger: "blur"
        },
      ],

      account: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (value === undefined || !validateUname(value)) {
              return callback(new Error('必填：6~20位纯数字 或 纯字母 或 数字+字母组成，区分大小写。'))
            }

            callback()
          },
          trigger: "blur",
        }
      ],

      password: [
        {
          required: true,
          message: "必填",
          trigger: "blur"
        },
        {
          min: 6,
          max: 20,
          message: "长度应在 6 到 20 个字符之间",
        },
      ],

      mobile: [
        {
          required: true,
          validator: mobileValidator.validator,
          trigger: "blur"
        },
      ],

      contact_name: [
        {
          required: true,
          message: "必填",
          trigger: "blur"
        },
      ],

      contact_mobile: [
        {
          required: true,
          validator: mobileValidator.validator,
          trigger: "blur"
        },
      ],
      contact_mobile2: [
        {
          validator: mobileValidator.validator2,
          trigger: "blur"
        },
      ],
      cadre_name: [
        {
          required: true,
          message: "必填",
          trigger: "blur"
        },
      ],
    }
  }

  reset (data = {}) {
    this.data = {
      id: data.shop_id,
      type: data.account_period || 0, // 类型
      name: data.shop_name, // 名称
      account: data.uname, // 账号
      mobile: data.mobile, // 绑定手机号
      password: data.password, // 密码
      contact_name: data.link_name, // 联系人姓名
      contact_mobile: data.link_phone, // 联系人手机号
      checked: !!data.receive_order_sms, // 是否接收订单短信提醒
      contact_mobile2: data.link_phone2, // 联系人手机号2
      checked2: !!data.receive_order_sms2, // 是否接收订单短信提醒
      cadre_name: data.principal_name, // 负责人姓名
    };
  }

  toData () {
    const data = this.data;

    return {
      shop_id: data.id,
      account_period: data.type, // 类型
      shop_name: data.name, // 名称
      uname: data.account, // 账号
      mobile: data.mobile, // 绑定手机号
      password: data.password, // 密码
      link_name: data.contact_name, // 联系人姓名
      link_phone: data.contact_mobile, // 联系人手机号
      receive_order_sms: +data.checked, // 是否接收订单短信提醒
      link_phone2: data.contact_mobile2, // 联系人手机号2
      receive_order_sms2: +data.checked2, // 是否接收订单短信提醒2
      principal_name: data.cadre_name, // 负责人姓名
    };
  }

  doSave () {
    this.getForm().validate((valid) => {
      if (valid) {
        const { id } = this.data;

        (() => {
          if (id) {
            return API_supplier.editShop;
          } else {
            return API_supplier.addShop;
          }
        })()(this.toData(), id).then((res) => {
          const { GET_AccountList, $message } = this.getPage();

          GET_AccountList();
          this.hide();
          $message.success(this.isEditMode ? '供应商信息修改成功' : '供应商创建成功');
        });
      }
    });
  }
}

export default {
  name: "supplierList",
  components: { EnTableLayout },
  data () {
    const supplier = new Supplier(this, 'supplierForm');

    return {
      // 供应商编辑
      supplier,

      cgooddata: {
        shop_name: "",
        shop_id: "",
        price: "",
        reason: "",
      },
      value: null,
      ca_statusList: ["待审核", "审核中", "已结算", "不通过"],

      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        shop_type: 2,
        start_time: "",
        account_period: '',
      },

      keywords: {
        currentKey: 'shop_name',
        value: undefined,
        keys: [
          ['供应商名称', 'shop_name'],
          ['供应商账号', 'member_name'],
          ['供应商ID', 'shop_id'],
          ['联系人姓名', 'link_name'],
          ['负责人名称', 'principal_name'],
        ]
      },

      batchOptions: [
        { label: "批量启用", value: 1 },
        { label: "批量停用", value: 2 },
        { label: "批量删除", value: 3 },
      ],

      daterangeDate: "",

      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: [],

      // 发送站内信
      mesForm: {
        notice_content: "",
        title: "",
        shop_ids: "",
        shop_type: 2,
      },
      // 审核商品 表单校验
      goodsAuditRules: {
        title: [
          { required: false, message: "请输入站内信标题！", trigger: "blur" },
        ],
        notice_content: [
          { required: false, message: "请输入站内信内容！", trigger: "blur" },
        ],
      },
      //扣除质保金
      cgooddataRules: {
        price: [
          { required: true, message: "请输入变更质保金！", trigger: "change" },
        ],
        reason: [
          { required: true, message: "请输入理由！", trigger: "change" },
        ],
      },
      rules: {
        title: [{ required: true, message: "请输入标题！", trigger: "change" }],
        notice_content: [
          { required: true, message: "请输入内容！", trigger: "change" },
        ],
      },
      // 发送站内信 dialog
      senMessageBool: false,
      // 短信提醒发货
      isSmsTip: false,
      // 控制短信提醒点击确认只点击一次
      isConfirmSms: true,
      //添加供应商dialog
      dialogGood: false,
      cdialogGood: false,
      // 供应商名称是否是京东供应商 是京东供应商不可发送短信
      isClickSms: false,
      time: "",
      shopid: "",
    };
  },
  watch: {
    // shopid: function (newVal) {
    //   this.gooddataRules.password[0].required = !newVal;
    // },
    // 监听添加供应商手机号的变化，如果手机号为空，就取消勾选
    'supplier.data.contact_mobile': {
      handler (newVal) {
        if (this.supplier.data.checked && !newVal) {
          this.supplier.data.checked = false;
        }
      }
    },
    'supplier.data.contact_mobile2': {
      handler (newVal) {
        if (this.supplier.data.checked2 && !newVal) {
          this.supplier.data.checked2 = false;
        }
      }
    },
    // 监听供应商名称的变化，如果是京东供应商，就不可发送短信
    'supplier.data.name': {
      handler (newVal) {
        if (newVal === '京东供应商') this.isClickSms = true, this.supplier.data.checked = this.supplier.data.checked2 = false;
        else this.isClickSms = false;
      },
    }
  },
  activated () {
    this.GET_AccountList();
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    exportAll () {
      this.GET_AccountList({
        isExport: true,
        page_no: 1,
        page_size: 999999
      }).then(r => {
        // debugger;
        if (!Array.isArray(r) || !r.length) return;
        this.loading = true
        var wb = XLSX.utils.book_new();
        const t_data = r.map(obj => {
          return [
            obj.shop_id,
            obj.shop_name,
            obj.uname,
            // obj.link_name,
            obj.link_phone,
            obj.principal_name,
            obj.account_period === 1 ? '有账期' : '无账期',
            obj.order_sum
          ]
        })
        // debugger
        var ws = XLSX.utils.aoa_to_sheet([
          ["供应商ID", "供应商名称", "供应商账户", /**"联系人", */ "联系方式", "负责人", "类型", "待发货订单"],
          ...t_data
        ]);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const file_wb = XLSX.write(wb, {
          type: 'buffer'
        });
        saveAs(new Blob([file_wb]), `供应商列表_${Date.now()}.xlsx`)
        this.loading = false
        this.$message({
          message: '正在下载文件，请稍等...',
          type: 'success',
          duration: 4000
        });
      })
    },
    openConmpany (shop_ids, params) {
      API_shop.openCompany(shop_ids, params).then((res) => {
        this.$message({
          showClose: true,
          message: res.message,
          type: "success",
        });
        this.GET_AccountList();
      });
    },
    switchChange (item) {
      let params = {
        shop_ids: [item.shop_id],
        disabled: 0,
      };

      if (!item.memberDisbled) {
        params.disabled = -1;
      }
      this.openConmpany([item.shop_id], params);
    },
    senMessage () {
      if (this.idsList.length === 0) {
        this.$message.error("请勾选供应商");
        return;
      }
      this.senMessageBool = true;
    },
    runBatchOps (command) {
      if (this.idsList.length === 0) {
        this.$message.error("请勾选供应商");
        return;
      }

      const opText = (() => {
        switch (command) {
          case 1:
            return '启用';
          case 2:
            return '停用';
          case 3:
            return '删除';
        }
      })();

      this.$confirm(`确认批量${opText}这些供应商？`, "温馨提示", {
        type: "warning",
      }).then(() => {
        switch (command) {
          case 1:
            let params = {
              shop_ids: this.idsList,
              disabled: 0,
            };
            // console.log("批量启用", this.idsList, params);
            this.openConmpany(this.idsList, params);
            break;
          case 2:
            let params1 = {
              shop_ids: this.idsList,
              disabled: -1,
            };
            // console.log("批量停用", this.idsList, params1);
            this.openConmpany(this.idsList, params1);
            break;
          case 3:
            // console.log("批量删除", this.idsList);
            API_shop.deleteCompany(this.idsList).then((res) => {
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
              });
              this.GET_AccountList();
            });
            break;
          default:
            console.log("误操作");
        }
      });
    },
    cancel () {
      this.senMessageBool = false;
      this.$refs["mesForm"].resetFields();
    },
    send (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.idsList.length > 0) {
            this.mesForm.shop_ids = this.idsList;
            API_shop.sendMessage(this.mesForm).then((res) => {
              this.$message({
                showClose: true,
                message: "发送成功",
                type: "success",
              });
              this.senMessageBool = false;
              this.$refs[formName].resetFields();
            });
          } else {
            this.$message.error("请勾选供应商");
            return;
          }
        } else {
          this.$message.error("请核对数据！");
          return false;
        }
      });
    },
    search () {
      const { keys, currentKey } = this.keywords;

      // this.params = {
      //   page_no: 1,
      //   page_size: 20,
      //   shop_type: 2,
      //   start_time: "",
      //   account_period: '',
      // };

      // 删除原来的值
      keys.forEach(item => {
        delete this.params[item[1]];
      });

      // 注入新的值
      this.params[currentKey] = this.keywords.value;

      this.GET_AccountList();
    },
    timeChange () {
      if (!this.time) {
        this.params.start_time = "";
        this.params.end_time = "";
        return;
      }
      this.params.start_time = new Date(this.time).getTime() / 1000;
      this.params.end_time = new Date(this.time).getTime() / 1000;
    },
    /** 列表选择发生改变 */
    handleSelectionChange (val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.shop_id);
      });
      this.idsList = ids;
    },
    /** 排序发生改变 */
    handleSortChange ({ order, prop }) {
      if (order === 'ascending') {
        order = 'asc';
      }

      if (order === 'descending') {
        order = 'desc';
      }

      if (order) {
        this.params[prop] = order;
      } else {
        delete this.params[prop];
      }

      this.GET_AccountList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    // 获取列表
    GET_AccountList (coverOpt = {}) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        API_supplier.getShopList({ ...this.params, ...coverOpt }).then((response) => {
          this.loading = false;
          if (coverOpt.isExport) {
            resolve(response.data)
          } else {
            if (response.page_no !== 1 && response.data.length === 0) {
              this.params.page_no = 1
              this.GET_AccountList()
            } else {
              response.data.forEach((val) => {
                val.memberDisbled = val.memberDisbled === 0;
              });
              this.tableData = response;
            }
          }
        }).catch(e => reject(e));
      })
    },
    // 添加店铺
    submitGoodsAuditForm (formName) {
      const { shopid, gooddata } = this;
      var type = shopid ? "editShop" : "addShop";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var postdata = { ...gooddata };
          API_supplier[type](postdata, shopid).then((res) => {
            this.dialogGood = false;
            this.GET_AccountList();
            this.$refs[formName].resetFields();
          });
        }
      });
    },

    // 账户状态改变
    zhchange (v, d) {
      var disabled = v == true ? 0 : -1;
      API_supplier.switchShop(d, { shop_id: d, disabeld: disabled }).then(
        (res) => {
          console.log(res);
        }
      );
    },

    // 禁用店铺
    desupplier (row) {
      this.$confirm("确定要删除这个店铺吗？", "提示", { type: "warning" })
        .then(() => {
          API_supplier.closeShop(row.shop_id).then((response) => {
            this.$message.success("删除成功！");
            this.GET_AccountList();
          });
        })
        .catch(() => {
        });
    },

    // 启用店铺
    desuppliers (row) {
      this.$confirm("确定要启用这个店铺吗？", "提示", { type: "warning" })
        .then(() => {
          API_supplier.closeShop(row.shop_id).then((response) => {
            this.$message.success("启用成功！");
            this.GET_AccountList();
          });
        })
        .catch(() => {
        });
    },

    // 查看变更记录
    changehis (row) {
      this.$router.push("/supplier/retentionChange/" + row.shop_id);
    },

    // 编辑供应商
    editSupplier (id) {
      API_supplier.getShop(id, { shop_id: id }).then((res) => {
        this.supplier.show(res);
      });
    },

    // 添加编辑供应商清除验证
    dialogGoodsClose () {
      this.$refs["gooddata"].resetFields();
      this.dialogGood = false;
      this.shopid = "";
      return true;
    },

    // 发送站内信清楚验证
    dialogGoodsClosez () {
      this.$refs["goodsAuditForm"].resetFields();
      this.dialogGoodsAuditVisible = false;
    },

    // 质保金
    changezhibao (val) {
      console.log(val);

      this.cgooddata.shop_id = val.shop_id;
      this.cgooddata.shop_name = val.shop_name;
      this.cgooddata.price = val.price;
      this.cgooddata.reason = val.reason;
      this.cdialogGood = true;
      this.$nextTick(() => {
        this.$refs["cgooddata"].clearValidate();
      });
    },

    // 扣除质保金
    handleStockGoods (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_supplier.addMoneyLog(this.cgooddata).then((res) => {
            this.cdialogGood = false;
            this.$message.success("操作成功！");
            this.$refs[formName].resetFields();
          });
        }
      });
    },
    /** 发送短信提醒 */
    async smsTip () {
      const res = await API_supplier.canSmsRemind2Delivery()
      if (res) this.isSmsTip = true;
      else this.$message.error("该功能1小时内不允许再次操作");
    },
    /** 确认发送短信 */
    async confirmSendSms () {
      if (this.isConfirmSms) {
        this.isConfirmSms = false; // 防止重复点击
        try {
          await API_supplier.smsRemind2Delivery({ ...this.params })
          this.$message.success("发送成功");
        } finally {
          this.isConfirmSms = true; // 重置
          this.isSmsTip = false; // 关闭弹窗
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
// ::v-deep .el-form-item--small.el-form-item {
//   margin-bottom: 12px;
// }
</style>
<style lang="scss">
.supplier-list-page {}

.toolbar-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sms-tip {
  .el-dialog {
    width: 38%;
  }
}

.supplier-edit-dialog {
  .group-title {
    padding-left: 16px;
    padding-bottom: 16px;
    font-size: 17px;
    font-weight: bold;
    color: #333;
  }
}
</style>
